/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Reference, Service } from '../models';

@Injectable()
export class ReferenceService {
  public referenceSubject = new BehaviorSubject<Reference|null>(null);
  // 	TODO: hidden by https://kostylworks.atlassian.net/browse/RR-500
  public servicesSubject = new BehaviorSubject<Service[]>([
    // {
    //   title: 'Электронная книга',
    //   service_key: 'ebook',
    //   service_type: 'бесплатная',
    //   cost: null,
    //   costRoyalty: '100 c с каждой продажи',
    //   activity_length: '2 года',
    //   activity_end: faker.date.future(),
    //   distribution: 'Литрес и Google Play',
    //   reporting: '1 раз в квартал, в разрезе всех площадок партнеров',
    //   printing: null
    // },
    {
      title: 'Печать для себя',
      service_key: 'print-for-self',
      service_type: 'разовая',
      cost: null,
      costRoyalty: null,
      activity_length: 'в процессе выполнения',
      activity_end: null,
      distribution: null,
      reporting: null,
      printing: 'формат А5, черно-белая печать, мягкая обложка'
    },
    {
      title: 'Выкуп складского остатка',
      service_key: 'buy-book',
      service_type: 'разовая',
      cost: null,
      costRoyalty: null,
      activity_length: 'в процессе выполнения',
      activity_end: null,
      distribution: null,
      reporting: null,
      printing: null,
    }
    // {
    //   title: 'Аудио-книга',
    //   service_key: 'audiobook',
    //   service_type: 'бесплатная',
    //   cost: null,
    //   costRoyalty: 200,
    //   activity_length: '2 года',
    //   activity_end: faker.date.future(),
    //   distribution: 'Сторител, Букмейт и Литрес',
    //   reporting: '1 раз в квартал, в разрезе всех площадок партнеров',
    //   printing: null
    // },
    // {
    //   title: 'Маркетинговое продвижение',
    //   service_key: 'distribution',
    //   service_type: 'разовая',
    //   cost: null,
    //   costRoyalty: 200,
    //   activity_length: '2 года',
    //   activity_end: faker.date.future(),
    //   distribution: 'Сторител, Букмейт и Литрес',
    //   reporting: '1 раз в квартал, в разрезе всех площадок партнеров',
    //   printing: null
    // }
  ] as Service[]);

  constructor(private apiService: ApiService) {}

  getReference(): Observable<any> {
    return this.apiService.get('/v1/ref-books').pipe(
      map((data: any) => data.data),
      tap(data => this.referenceSubject.next(data))
    );
  }
}
