<!-- @format -->

<div class="box d-flex">
	<div
		class="inner d-flex rounded-sm bg-color-neutral_white bordered overflow-h"
		*ngIf="servicesReceipt"
	>
		<div class="service d-flex ai-center jc-start" *ngIf="servicesMap as service">
			<app-svg-icon
				class="d-block"
				[appIcon]="service.icon"
				[appWidth]="service['iconWidth']"
				[appHeight]="service['iconHeight']"
				[appViewBox]="'0 0' + ' ' + service['iconWidth'] + ' ' + service['iconHeight']"
			></app-svg-icon>
			<span class="d-block text-color-log-print_dark-gray h6 text-bold ml-1">
				{{ service.label }}
			</span>
		</div>
		<div class="book h6 mt-3 pt-3" *ngIf="servicesReceipt.book">
			<div>{{ servicesReceipt.book.title }}</div>
			<div *ngIf="servicesReceipt.book.isbn" class="text-color-neutral_gray-2">({{ servicesReceipt.book.isbn }})</div>
		</div>
		<div
			class="row d-flex ai-start jc-start h6 mt-3"
			*ngIf="servicesReceipt.receipt_row?.length"
		>
			<ul class="list w-full p-0 m-0">
				<li
					class="list-item w-full"
					*ngFor="let receiptRow of servicesReceipt.receipt_row"
				>
					<div class="w-full d-flex fd-column ai-start jc-start">
						<span class="d-block text-color-log-print_dark-gray h7 text-bold">
							{{ receiptRow.title }}
						</span>
						<div class="w-full text-color-log-print_dark-gray h7" *ngIf="receiptRow.description">
							<ul class="w-full p-0 m-0">
								<li
									class="d-flex justify-stretch ai-center my-h"
									*ngFor="let description of receiptRow.description"
								>
									<span class="text-color-log-print_dark-gray h7 mr-h">
										{{ description.label }}
									</span>
									<span class="grow text-color-neutral_gray-2 h7">
										{{ description.value }}
									</span>
									<span>
										<span
											class="shrink text-color-neutral_dark h6 text-nowrap"
											*ngIf="!description.isFree && description.price !== undefined"
										>
											{{ description.price || "0" | mask: "separator.2" | replace: "\\.":"," }}
											<span class="text-weight-600 ml-h">&#x20bd;</span>
										</span>
										<span
											class="shrink text-color-neutral_dark h6 text-nowrap"
											*ngIf="!!description.isFree"
										>
											бесплатно
										</span>
									</span>
								</li>
							</ul>
						</div>
					</div>
					<!--<div
						class="sum text-color-neutral_dark h6 text-bold text-nowrap"
						*ngIf="!receiptRow.isFree && receiptRow.sum !== undefined"
					>
						{{ receiptRow.sum || "0" | mask: "separator.2" | replace: "\\.":"," }}
						<span class="text-weight-600 h6 ml-h">&#x20bd;</span>
					</div>
					<div
						class="sum text-color-neutral_dark h6 text-bold text-nowrap"
						*ngIf="!!receiptRow.isFree"
					>
						бесплатно
					</div>-->
				</li>
			</ul>
		</div>
		<div
			class="total d-flex ai-center jc-between bg-color-log-print_main-violet"
			*ngIf="servicesReceiptTotal"
		>
			<div class="text-color-neutral_white h5 text-bold">Итого:</div>
			<div
				class="d-flex ai-center jc-end text-color-neutral_white h5 text-bold text-nowrap"
			>
				{{ servicesReceiptTotal | mask: "separator.2" | replace: "\\.":"," }}
				<span class="d-block text-weight-600 h6 ml-h">&#x20bd;</span>
			</div>
		</div>
	</div>
</div>
